import { Railroad, Station, stationMap } from './Types'
import stationPairs from './data/stationPairs.json'
import _ from 'lodash'
import { OptionProps } from '@blueprintjs/core/src/common/props'

export function validStations(origin: Station): Station[] {
    const departures = _.map(_.filter(stationPairs, [0, origin.tariffId]), 1)
    const arrivals = _.map(_.filter(stationPairs, [1, origin.tariffId]), 0)

    return _.sortBy(
        _.map(_.uniq(departures.concat(arrivals)), c => stationMap[c]),
        'name'
    )
}

export function stationToValue(sta: Station): OptionProps {
    return { label: sta.name, value: sta.tariffId }
}

export function isFarRockawayTrip(railroad: Railroad, origin?: Station, dest?: Station): boolean {
    if (origin === null || dest === null) return false

    return railroad === 'LI' && dest.zone <= 3 && origin.name === 'Far Rockaway'
}

export function getUrlParam(url: URL, variableName: string, defaultState: any) {
    if (url.searchParams.has(variableName)) {
        return url.searchParams.get(variableName)
    }
    return defaultState
}
