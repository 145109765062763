import { createRoot } from 'react-dom/client'
import ReactGA from 'react-ga4'
import './index.css'

import App from './App'

ReactGA.initialize('G-GSSTY2SZBM')

const container = document.getElementById('root')
const root = createRoot(container!)
root.render(<App />)
