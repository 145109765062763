import _ from 'lodash'
import { cleanTickets, FarRockawayTickets, Railroad, Ticket } from './Types'

function optionsForRidesInner(
    rides: number,
    tickets: Ticket[],
    priorTickets: Ticket[]
): Ticket[][] {
    console.assert(rides > 0 && !_.isEmpty(tickets))

    const options = []
    _.forEach(tickets, t => {
        const numNeeded = Math.max(
            1,
            t.monthlyRides === 1 ? rides : Math.floor(rides / t.monthlyRides)
        )
        const withThisTicket = priorTickets.concat(_.times(numNeeded, _.constant(t)))
        const ridesRemaining = rides - numNeeded * t.monthlyRides
        if (ridesRemaining <= 0 || t.monthlyRides === 1) {
            options.push(withThisTicket)
        } else {
            const smallerTickets = _.filter(tickets, u => u.monthlyRides <= t.monthlyRides)
            options.push(...optionsForRidesInner(ridesRemaining, smallerTickets, withThisTicket))
        }
    })

    return options
}

function optionsForRides(trips: number, tickets: Ticket[], prefix: Ticket[]): Ticket[][] {
    console.assert(!_.isEmpty(tickets))
    if (trips === 0) return [[]]

    const ticketsOrdered = _.orderBy(tickets, 'monthlyRides', 'desc')
    const allOptions = optionsForRidesInner(trips, ticketsOrdered, [])
    const prefixedOptions = _.map(allOptions, o => prefix.concat(o))

    return _.uniqWith(prefixedOptions, (a, b) =>
        _.isEqual(_.map(a, 'name').sort(), _.map(b, 'name').sort())
    )
}

function optimalPurchase(options: Ticket[][]): Ticket[] {
    if (_.isEmpty(options)) return []

    return _.head(_.sortBy(options, [o => _.sumBy(o, 'monthlyCost'), 'length']))
}

function removeRedundantTickets(tickets: Ticket[]): Ticket[] {
    const grouped = Object.values(_.groupBy(tickets, 'monthlyRides'))
    return _.map(grouped, options => _.minBy(options, 'monthlyCost')).flat()
}

function addSegment(trips: number, tickets: Ticket[], priorSegment: Ticket[][]): Ticket[][] {
    const priorOptimal = optimalPurchase(priorSegment)

    if (_.sumBy(priorOptimal, 'monthlyRides') >= trips) {
        return priorSegment
    } else {
        return _.flatMap(priorSegment, opt => {
            const ridesRemaining = trips - _.sumBy(opt, 'monthlyRides')
            if (ridesRemaining <= 0) {
                return [opt]
            } else {
                return optionsForRides(ridesRemaining, tickets, opt)
            }
        })
    }
}

export function calculateFares(
    railroad: Railroad,
    origin: number,
    dest: number,
    numAMPeak: number,
    numPMPeak: number,
    numOffPeak: number,
    sameDay: boolean,
    reducedFare: boolean,
    farRockaway: boolean
): Ticket[] {
    const totalRides = numOffPeak + numAMPeak + numPMPeak
    if (totalRides === 0) return undefined

    const validTickets = _.filter(
        cleanTickets.concat(farRockaway ? FarRockawayTickets : []),
        t =>
            _.isMatch(t, {
                railroad,
                fromZone: Math.min(origin, dest),
                toZone: Math.max(origin, dest)
            }) &&
            !t.name.includes('Weekly') &&
            (sameDay || !t.sameDay) &&
            (reducedFare || !t.reducedFare)
    )

    if (_.isEmpty(validTickets)) return []

    const offPeakTickets = removeRedundantTickets(validTickets)

    let peakOptions
    if (reducedFare) {
        const peakTickets = _.filter(validTickets, ['validPeak', true])
        const amPeakTickets = removeRedundantTickets(_.reject(peakTickets, ['reducedFare', true]))

        const amPeakOptions = optionsForRides(numAMPeak, amPeakTickets, [])
        peakOptions = addSegment(
            numAMPeak + numPMPeak,
            removeRedundantTickets(peakTickets),
            amPeakOptions
        )
    } else {
        const peakTickets = removeRedundantTickets(_.filter(validTickets, ['validPeak', true]))
        peakOptions = optionsForRides(numAMPeak + numPMPeak, peakTickets, [])
    }

    const withOffPeak = addSegment(totalRides, offPeakTickets, peakOptions)
    return _.orderBy(optimalPurchase(withOffPeak), 'cost', 'desc')
}
