export const Header = () => (
    <header>
        <nav className="flex flex-wrap bg-black text-white py-4 px-4 items-center">
            <div className="flex flex-grow items-center">
                <div className="mr-2">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="51"
                        width="47"
                        viewBox="0 0 47 51"
                    >
                        <path
                            d="M29.909 21.372l-2.743-.234v14.56l-4.088.724-.01-15.644-3.474-.308v-5.734l10.315 1.803v4.833zm7.785 12.484l-2.426.421-.283-2.122-2.363.307-.296 2.335-3.125.553 3.094-18.36 2.937.51 2.462 16.356zm-3.141-5.288l-.65-5.606h-.142l-.658 5.691 1.45-.085zM21.038 50.931c13.986 0 25.32-11.402 25.32-25.465C46.359 11.4 35.025 0 21.039 0 12.27 0 4.545 4.483 0 11.296l7.017 1.237 1.931 14.78c.007-.024.14-.009.14-.009l2.118-14.036 7.022 1.229V37.28l-4.432.776v-9.79s.164-4.217.067-4.938c0 0-.193.005-.196-.011l-2.644 15.236-4.403.777-3.236-16.412-.195-.014c-.069.594.237 5.744.237 5.744v11.243L.532 40.4c4.603 6.38 12.072 10.53 20.506 10.53v.001z"
                            fill="#FFF"
                        />
                    </svg>
                </div>
                <div className="text-white text-xl font-bold">Find Your Best Fare</div>
            </div>
            <div>
                <a href="https://new.mta.info" className="hover:text-white text-white font-bold">
                    Back to MTA.info
                </a>
            </div>
        </nav>
    </header>
)
