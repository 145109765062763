import { Checkbox, FormGroup, HTMLSelect, Slider } from '@blueprintjs/core'
import { Railroad, Station, stationMap } from '../Types'
import _ from 'lodash'
import { stationToValue, validStations } from '../Utils'
import React, { useEffect, useState } from 'react'

export function Controls(props: {
    railroad: Railroad
    originStation: Station
    setOriginStation: (Station) => void
    destStation: Station
    setDestStation: (Station) => void
    numAMPeak: number
    setNumAMPeak: (number) => void
    numPMPeak: number
    setNumPMPeak: (number) => void
    numOffPeak: number
    setNumOffPeak: (number) => void
    sameDay: boolean
    setSameDay: (boolean) => void
    reducedFare: boolean
    setReducedFare: (boolean) => void
}) {
    const [legalDestStations, setLegalDestStations] = useState<Station[]>()

    const originStation = props.originStation
    // Always set valid (destination) stations as a function of origin station
    useEffect(() => {
        const valid = validStations(originStation)
        setLegalDestStations(valid)
    }, [originStation])

    const setNumPeak = (trips: number) => {
        props.setNumAMPeak(trips)
        props.setNumPMPeak(0)
    }

    const destStation = props.destStation
    const setDestStation = props.setDestStation
    const isMN = props.railroad === 'MN'

    const railroadMatch = (test: Station) =>
        props.railroad === 'LI'
            ? test.railroad === 'LI'
            : test.railroad === 'MN' || test.railroad === 'NJ'

    return (
        <div className="flex flex-wrap md:-mx-4">
            <div className="w-full md:w-1/2 md:px-4">
                <div className="mta-select">
                    <FormGroup label={'Origin'} inline={true}>
                        <HTMLSelect
                            onChange={e => {
                                const origin = stationMap[e.currentTarget.value]
                                props.setOriginStation(origin)
                                const valid = validStations(origin)
                                if (!_.includes(valid, destStation)) {
                                    if (_.isEmpty(valid)) {
                                        setDestStation(undefined)
                                    } else {
                                        setDestStation(valid[0])
                                    }
                                }
                            }}
                            options={_.sortBy(
                                _.map(_.filter(stationMap, railroadMatch), stationToValue),
                                'label'
                            )}
                            value={originStation.tariffId}
                        />
                    </FormGroup>
                </div>
                {props.originStation.railroad === 'NJ' ? (
                    <div className="text-white">
                        <span>For West of Hudson ticket information, visit the </span>
                        <a
                            style={{ color: '#ffffff', fontWeight: 'bold' }}
                            href={'https://www.njtransit.com/tickets/train-tickets'}
                        >
                            NJ Transit website
                        </a>
                        .
                    </div>
                ) : (
                    <>
                        <div className="mta-select">
                            <FormGroup label={'Destination'} inline={true}>
                                <HTMLSelect
                                    onChange={e =>
                                        props.setDestStation(stationMap[e.currentTarget.value])
                                    }
                                    options={_.map(
                                        _.sortBy(legalDestStations, 'name'),
                                        stationToValue
                                    )}
                                    value={destStation.tariffId}
                                />
                            </FormGroup>
                        </div>
                        {props.railroad === 'LI' && (
                            <div className="text-lg mb-4">
                                Direct travel only - no changes of direction
                            </div>
                        )}
                        <hr className="mb-6" />
                        <Checkbox
                            checked={props.reducedFare}
                            label={
                                'Show reduced fares for seniors (65+), people with disabilities, and Medicare recipients'
                            }
                            onChange={e => props.setReducedFare(e.currentTarget.checked)}
                        />
                    </>
                )}
            </div>
            <div className="w-full md:w-1/2 md:px-4">
                {props.originStation.railroad !== 'NJ' && (
                    <>
                        <FormGroup
                            label={`${
                                props.reducedFare ? (isMN ? 'AM Peak Inbound' : 'AM Peak') : 'Peak'
                            } rides per month`}
                            style={{ width: 'min(400px, 75%)' }}
                        >
                            <Slider
                                onChange={props.reducedFare ? props.setNumAMPeak : setNumPeak}
                                value={
                                    props.reducedFare
                                        ? props.numAMPeak
                                        : props.numAMPeak + props.numPMPeak
                                }
                                min={0}
                                max={props.reducedFare ? 21 : 42}
                                labelStepSize={5}
                            />
                        </FormGroup>
                        {props.reducedFare && (
                            <FormGroup
                                label={`${
                                    isMN ? 'AM Peak Outbound + PM Peak' : 'PM Peak'
                                } rides per month`}
                                style={{ width: 'min(400px, 75%)' }}
                            >
                                <Slider
                                    onChange={props.setNumPMPeak}
                                    value={props.numPMPeak}
                                    min={0}
                                    max={props.reducedFare ? 21 : 42}
                                    labelStepSize={5}
                                />
                            </FormGroup>
                        )}
                        <FormGroup
                            label={'Off Peak rides per month:'}
                            style={{ width: 'min(400px, 75%)' }}
                        >
                            <Slider
                                onChange={props.setNumOffPeak}
                                value={props.numOffPeak}
                                min={0}
                                max={42}
                                labelStepSize={5}
                            />
                        </FormGroup>
                    </>
                )}
            </div>
        </div>
    )
}
