import { Ticket } from '../Types'
import _ from 'lodash'
import { AnchorButton, UL } from '@blueprintjs/core'
import React, { useEffect, useState } from 'react'
import ReactGA from 'react-ga4'

function footnote(ticket: Ticket): string {
    let noteStrings = []
    if (ticket.name.includes('Far Rockaway') && ticket.fromZone === 3)
        noteStrings.push('select Penn Station as your destination to purchase this ticket')
    if (ticket.sameDay) noteStrings.push('must buy on day of travel')

    const allStrings = noteStrings.join(', ')
    if (_.isEmpty(allStrings)) return null
    else return allStrings[0].toUpperCase() + allStrings.slice(1)
}

export function OptimalResult(props: { fareResult: Ticket[]; appUrl: string }) {
    const [errorMsg, setErrorMsg] = useState<String>(undefined)
    const fareResult = props.fareResult

    useEffect(() => {
        if (_.isUndefined(fareResult)) {
            setErrorMsg('Must select at least one trip')
        } else if (_.isEmpty(fareResult)) {
            setErrorMsg('No direct travel options for this trip')
        } else {
            setErrorMsg(undefined)
        }
    }, [fareResult])

    const totalCost = (_.sumBy(fareResult, 'monthlyCost') / 100).toFixed(2)
    const ticketTypes = _.countBy(_.map(fareResult, 'name'))
    const hasMonthly = _.some(props.fareResult, ['name', 'Monthly'])
    const currentDate = new Date().getDate()
    const showBuy = !hasMonthly || currentDate < 12 || currentDate >= 25

    return (
        <div className="bg-white p-6 md:p-10 my-20 border-t-2 border-yellow">
            <h2 className="text-2xl text-primary-blue font-bold mb-10">Your best buy</h2>
            {!_.isUndefined(errorMsg) ? (
                <div>{errorMsg}</div>
            ) : (
                <>
                    <UL className="list-disc text-lg mb-10">
                        {_.map(Object.keys(ticketTypes), k => {
                            const ticket = _.find(fareResult, ['name', k])
                            const priceEach = (ticket.cost / 100).toFixed(2)
                            let quantity = ticketTypes[k]
                            let perMonths = ticket.factor

                            // Crude fraction reduction function
                            if (quantity === perMonths) {
                                quantity = 1
                                perMonths = 1
                            } else if (quantity % perMonths === 0) {
                                quantity = quantity / perMonths
                                perMonths = 1
                            } else if (quantity % 2 === 0 && perMonths % 2 === 0) {
                                quantity = quantity / 2
                                perMonths = perMonths / 2
                            } else if (quantity % 3 === 0 && perMonths % 3 === 0) {
                                quantity = quantity / 3
                                perMonths = perMonths / 3
                            }

                            const displayStr = `${quantity}${quantity > 1 ? 'x' : ''} ${k} every ${
                                perMonths > 1 ? `${perMonths} months` : 'month'
                            } @ $${priceEach}${quantity > 1 ? ' each' : ''}`

                            const restrictions = footnote(ticket)
                            return (
                                <li key={k}>
                                    {displayStr}
                                    {!_.isNull(restrictions) && (
                                        <UL className="text-sm">
                                            <li>{restrictions}</li>
                                        </UL>
                                    )}
                                </li>
                            )
                        })}
                    </UL>
                    <div className="text-xl">
                        <b>Total monthly cost: ${totalCost}</b>
                    </div>
                    {/* Only shown on mobile even when `showBuy` is true */}
                    {showBuy && (
                        <AnchorButton
                            href={props.appUrl}
                            target="_blank"
                            text="Buy"
                            className="mta-button"
                            onClick={() => {
                                ReactGA.event({ category: 'User', action: 'Buy' })
                            }}
                        />
                    )}
                    {!showBuy && (
                        <div className="text-lg">
                            ℹ️ Monthlies are only available for purchase starting on the 25th of the
                            month. For example, the monthly ticket for August will be available on
                            July 25th.
                        </div>
                    )}
                </>
            )}
        </div>
    )
}
