import { FarRockawayTickets, Railroad, Station, Ticket } from '../Types'
import _ from 'lodash'
import tickets from '../data/tickets.json'
import { useCallback, useEffect, useState } from 'react'
import { isFarRockawayTrip } from '../Utils'

function validOnString(railroad: Railroad, ticket: Ticket): string {
    if (ticket.reducedFare) return `All trains except ${railroad === 'MN' ? 'inbound ' : ''}AM Peak`
    else if (ticket.validPeak) return 'All trains'
    else return 'Off peak trains only'
}

function breakEven(ticket: Ticket, peak: boolean, oneWay: Ticket): string {
    if (ticket.numRides === 1) return 'N/A'
    if (peak && !ticket.validPeak) return 'N/A'

    const breakEven = Math.ceil(ticket.cost / oneWay.cost)

    return breakEven > ticket.numRides ? 'N/A' : breakEven.toString() + ' trips per month'
}

export function FareTable(props: {
    railroad: Railroad
    origin: Station
    dest: Station
    reducedFare: boolean
}) {
    const farRockaway = isFarRockawayTrip(props.railroad, props.origin, props.dest)

    const computeValidTickets = useCallback(() => {
        return _.sortBy(
            _.filter(
                tickets.concat(farRockaway ? FarRockawayTickets : []) as Ticket[],
                t =>
                    _.isMatch(t, {
                        railroad: props.railroad,
                        fromZone: Math.min(props.origin.zone, props.dest.zone),
                        toZone: Math.max(props.origin.zone, props.dest.zone)
                    }) &&
                    (props.reducedFare || !t.reducedFare)
            ),
            'cost'
        )
    }, [props, farRockaway])

    const [validTickets, setValidTickets] = useState<Ticket[]>(computeValidTickets())

    useEffect(() => setValidTickets(computeValidTickets()), [computeValidTickets])
    if (_.isEmpty(validTickets)) return null

    const peakOneWays = _.filter(validTickets, { numRides: 1, validPeak: true, reducedFare: false })
    const cheapestPeak = _.minBy(peakOneWays, 'cost')

    const offPeakOneWays = _.filter(validTickets, ['numRides', 1])
    const cheapestOffPeak = _.minBy(offPeakOneWays, 'cost')

    const peakNote = peakOneWays.length > 1
    const offPeakNote = offPeakOneWays.length > peakOneWays.length + 1

    let note = ''
    if (peakNote && offPeakNote && !props.reducedFare) {
        note = `Break-even points are computed assuming the use of ${cheapestPeak?.name}.`
    } else if (peakNote && !props.reducedFare) {
        note = `Break-even points are computed assuming the use of ${cheapestPeak?.name} for peak rides.`
    } else if (offPeakNote && !props.reducedFare) {
        note = `Break-even points are computed assuming the use of ${cheapestOffPeak?.name} for off-peak rides.`
    }

    return (
        <>
            <h2 className="text-xl font-bold pb-2 border-b-2 border-black mb-4">
                {!farRockaway
                    ? `All ticket options between zones ${props.origin.zone} and ${props.dest.zone}`
                    : 'All ticket options'}
            </h2>
            <div className="mta-table mta-table-responsive">
                <table>
                    <thead>
                        <tr>
                            <th>Ticket</th>
                            <th>Price</th>
                            <th>Valid on</th>
                            <th>Valid for</th>
                            <th># Rides</th>
                            {!props.reducedFare && (
                                <>
                                    <th>Save after (peak)</th>
                                    <th>Save after (off peak)</th>
                                </>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {_.map(validTickets, t => {
                            return (
                                <tr key={t.name}>
                                    <td data-title="Ticket">
                                        <b>{t.name}</b>
                                    </td>
                                    <td data-title="Price">{`$${(t.cost / 100).toFixed(2)}`}</td>
                                    <td data-title="Valid on">
                                        {validOnString(props.railroad, t)}
                                    </td>
                                    <td data-title="Valid for">{t.validityStr}</td>
                                    <td data-title="# Rides">
                                        {t.numRides === 100 ? 'Unlimited' : t.numRides}
                                    </td>
                                    {!props.reducedFare && (
                                        <>
                                            <td data-title="Save after (peak)">
                                                {breakEven(t, true, cheapestPeak)}
                                            </td>
                                            <td data-title="Save after (off peak)">
                                                {breakEven(t, false, cheapestOffPeak)}
                                            </td>
                                        </>
                                    )}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
            <span>{note.trim()}</span>
        </>
    )
}
