import _ from 'lodash'
import stations from './data/stations.json'
import tickets from './data/tickets.json'

export interface Ticket {
    railroad: Railroad
    fromZone: number
    toZone: number
    name: string
    numRides: number
    validPeak: boolean
    cost: number
    sameDay: boolean
    reducedFare: boolean
    validity: number
    validityStr: string
    // Added later
    factor?: number
    monthlyRides?: number
    monthlyCost?: number
}

export type Railroad = 'LI' | 'MN' | 'NJ'

export interface Station {
    railroad: Railroad
    name: string
    zone: number
    tariffId: number
}

export const stationMap = _.keyBy(stations as Station[], 'tariffId')

export const cleanTickets = _.map(tickets as Ticket[], t => {
    t.factor = t.numRides > 1 ? t.validity / 30 : 1
    t.monthlyRides = t.numRides / t.factor
    t.monthlyCost = t.cost / t.factor
    return t
})

export const FarRockawayTickets: Ticket[] = [
    {
        railroad: 'LI',
        fromZone: 1,
        toZone: 4,
        name: 'Far Rockaway Ticket Off Peak',
        numRides: 1,
        validPeak: false,
        cost: 500,
        sameDay: true,
        reducedFare: false,
        validityStr: 'Day of purchase',
        validity: 1,
        factor: 1,
        monthlyRides: 1,
        monthlyCost: 500
    },
    {
        railroad: 'LI',
        fromZone: 1,
        toZone: 4,
        name: 'Far Rockaway Ticket Peak',
        numRides: 1,
        validPeak: true,
        cost: 700,
        sameDay: true,
        reducedFare: false,
        validityStr: 'Day of purchase',
        validity: 1,
        factor: 1,
        monthlyRides: 1,
        monthlyCost: 700
    },
    {
        railroad: 'LI',
        fromZone: 3,
        toZone: 4,
        name: 'Far Rockaway Ticket Off Peak',
        numRides: 1,
        validPeak: false,
        cost: 500,
        sameDay: true,
        reducedFare: false,
        validityStr: 'Day of purchase',
        validity: 1,
        factor: 1,
        monthlyRides: 1,
        monthlyCost: 500
    },
    {
        railroad: 'LI',
        fromZone: 3,
        toZone: 4,
        name: 'Far Rockaway Ticket Peak',
        numRides: 1,
        validPeak: true,
        cost: 700,
        sameDay: true,
        reducedFare: false,
        validityStr: 'Day of purchase',
        validity: 1,
        factor: 1,
        monthlyRides: 1,
        monthlyCost: 700
    }
]
