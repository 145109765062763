import React, { useEffect, useState } from 'react'
import { calculateFares } from './Calculator'
import { Railroad, Station, stationMap, Ticket } from './Types'
import { Header } from './components/Header'
import { Intro } from './components/Intro'
import { Controls } from './components/Controls'
import { OptimalResult } from './components/OptimalResult'
import { FareTable } from './components/FareTable'
import _ from 'lodash'
import { getUrlParam, isFarRockawayTrip } from './Utils'
import ReactGA from 'react-ga4'

ReactGA.send({ hitType: 'pageview', page: 'home' })

function App() {
    const url = new URL(window.location.href)
    const urlRr = getUrlParam(url, 'rr', 'LI')

    const [railroad, setRR] = useState<Railroad>(urlRr)
    const [originStation, setOriginStation] = useState<Station>(
        railroad === 'LI' ? stationMap[9012] : stationMap[1]
    )
    const [destStation, setDestStation] = useState<Station>(
        railroad === 'LI' ? stationMap[9194] : stationMap[132]
    )
    const [numAMPeak, setNumAMPeak] = useState(7)
    const [numPMPeak, setNumPMPeak] = useState(7)
    const [numOffPeak, setNumOffPeak] = useState(10)
    const [sameDay, setSameDay] = useState(true)
    const [reducedFare, setReducedFare] = useState(false)

    const [fareResult, setFareResult] = useState<Ticket[]>([])

    const appUrl = `com.justride.v1.MTA://purchase?to=${destStation?.tariffId}&from=${originStation.tariffId}`

    const setRailroad = (railroad: Railroad) => {
        setRR(railroad)
        setOriginStation(railroad === 'LI' ? stationMap[9012] : stationMap[1])
        setDestStation(railroad === 'LI' ? stationMap[9194] : stationMap[132])
    }

    useEffect(() => {
        if (!_.isUndefined(destStation)) {
            setFareResult(
                calculateFares(
                    railroad,
                    originStation.zone,
                    destStation.zone,
                    numAMPeak,
                    numPMPeak,
                    numOffPeak,
                    sameDay,
                    reducedFare,
                    isFarRockawayTrip(railroad, originStation, destStation)
                )
            )
        }
    }, [
        railroad,
        originStation,
        destStation,
        numAMPeak,
        numPMPeak,
        numOffPeak,
        sameDay,
        reducedFare
    ])

    return (
        <div>
            <Header />
            <div className="max-w-5xl mx-auto px-4 py-8 md:py-20">
                <Intro />
                <div className="bg-white flex items-center border-b-2 border-yellow text-lg">
                    <div>
                        <label
                            htmlFor="li"
                            className={`w-32 md:w-80 text-center cursor-pointer block px-4 py-4 text-dark-blue ${
                                railroad === 'LI' ? 'bg-yellow font-bold' : ''
                            }`}
                        >
                            <span className="hidden md:block">Long Island Rail Road</span>
                            <span className="md:hidden">LIRR</span>
                        </label>
                        <input
                            type="radio"
                            value="LI"
                            onChange={e => setRailroad(e.currentTarget.value as Railroad)}
                            name="agency"
                            id="li"
                            className="sr-only"
                        />
                    </div>
                    <div>
                        <label
                            htmlFor="mnr"
                            className={`w-32 md:w-80 text-center cursor-pointer block px-4 py-4 text-dark-blue ${
                                railroad === 'MN' ? 'bg-yellow font-bold' : ''
                            }`}
                        >
                            <span className="hidden md:block">Metro-North Railroad</span>
                            <span className="md:hidden">MNR</span>
                        </label>
                        <input
                            type="radio"
                            value="MN"
                            onChange={e => setRailroad(e.currentTarget.value as Railroad)}
                            name="agency"
                            id="mnr"
                            className="sr-only"
                        />
                    </div>
                </div>
                <div className="bg-primary-blue p-6 md:p-10 text-white">
                    <Controls
                        railroad={railroad}
                        originStation={originStation}
                        setOriginStation={setOriginStation}
                        destStation={destStation}
                        setDestStation={setDestStation}
                        numAMPeak={numAMPeak}
                        setNumAMPeak={setNumAMPeak}
                        numPMPeak={numPMPeak}
                        setNumPMPeak={setNumPMPeak}
                        numOffPeak={numOffPeak}
                        setNumOffPeak={setNumOffPeak}
                        sameDay={sameDay}
                        setSameDay={setSameDay}
                        reducedFare={reducedFare}
                        setReducedFare={setReducedFare}
                    />
                </div>
                {originStation.railroad !== 'NJ' && (
                    <>
                        <OptimalResult fareResult={fareResult} appUrl={appUrl} />
                        <FareTable
                            railroad={railroad}
                            origin={originStation}
                            dest={destStation}
                            reducedFare={reducedFare}
                        />
                    </>
                )}
            </div>
        </div>
    )
}

export default App
