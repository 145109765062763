export const Intro = () => (
    <div className="mb-12">
        <h1 className="text-xl md:text-3xl font-bold mb-6">
            Find your best fare on LIRR and Metro-North
        </h1>
        <div className="text-lg">
            <p className="mb-4">
                Need help deciding which tickets to buy on Long Island Rail Road and Metro-North?
                Use our tool to figure out your best option.
            </p>
            <p className="mb-4">
                All you have to do is enter the stations you're traveling to and from, and the
                number of rides you'll take during peak and off-peak hours. We'll tell you the best
                tickets to buy for your trip. You can also see every ticket option for your fare
                zones.
            </p>
            <p className="mb-4">
                The easiest way to buy tickets is with the TrainTime app on your phone. Download it
                from the{' '}
                <a href={'https://apps.apple.com/us/app/mta-traintime/id1104885987'}>App Store</a>{' '}
                or{' '}
                <a href={'https://play.google.com/store/apps/details?id=com.mta.mobileapp'}>
                    Google Play
                </a>
                .
            </p>
            <a href="https://new.mta.info" className="font-bold">
                Back to MTA.info &rarr;
            </a>
        </div>
    </div>
)
